<template>
	<div class="sala-grupos-editor">

		<div class="sala-grupos-container-editor">
			<HtmlEditor :dynamicArea="false" tools="" v-model="conteudo" class="sala-grupos-editor-container-texto" />
		</div>

		<div class="sala-grupos-container-botao">
			<button class="sala-grupos-botao-enviar" @click="enviarMensagem">
				<i class="fa fa-paper-plane" style="font-size: 24px;"></i>
				Enviar Mensagem
			</button>
		</div>

	</div>
</template>

<script>

	import HtmlEditor from "@/components/inputs/HtmlEditor";

	export default {
		name: 'SalaGruposEditor',

		components: {
			HtmlEditor
		},

		props: {
			mensagemPredefinida: {
				type: String,
				default: ''
			},
			anexos: {
				type: Array,
				default: () => []
			},
			userSDWW: {
				type: Boolean,
				default: false,
			},
		},

		data() {
			return {
				publico: true,
				conteudo: '',
				refBotaoAnexar: null,
				menuEditor: []
			}
		},

		watch: {
			mensagemPredefinida() {
				this.conteudo = this.mensagemPredefinida;
			}
		},

		beforeDestroy() {
			document.querySelector('.chat-atendimento-editor-botao-anexar')?.removeEventListener('click')
		},

		methods: {
			enviarMensagem() {
				if (!this.conteudo) {
					return
				}
				this.$emit('enviar', this.conteudo, this.publico)
				this.conteudo = ''
			}
		}
	}

</script>

<style scoped>
	.sala-grupos-editor {
		width: 100%;
		padding: 0;
		margin: 0;
		position: relative;
	}

	.sala-grupos-container-editor {
		width: 100%;
	}


	.sala-grupos-editor-container-texto {
		max-height: 150px;
	}

	.sala-grupos-container-botao {
		padding-top: 4px;
	}

	.sala-grupos-botao-enviar {
		border: none;
		background: var(--cor-primaria-cliente);
		color: #fff;
		height: 55px;
		width: 100%;
		padding: 15px;
		font-weight: bold;
	}

		.sala-grupos-botao-enviar:hover {
			opacity: 0.8;
		}

	.sala-grupos-editor-container-checkbox {
		width: 150px;
		height: 38px;
		text-align: center;
		padding: 10px;
		position: absolute;
		z-index: 1;
		right: 0;
	}

	.sala-grupos-editor-checkbox {
		display: inline-block;
		margin-right: 5px;
	}

	.sala-grupos-editor-checkbox-label {
		display: inline-block;
		margin: auto;
	}
</style>

<style>
	/* Estilos que serão aplicados a componentes que nao sao do Vue, melhor nao usar scoped */
	.sala-grupos-editor-container-texto {
		max-height: 150px;
	}

		.sala-grupos-editor-container-texto div[contenteditable] {
			min-height: 100px !important;
			max-height: 150px !important;
			width: 100%;
		}

	.sala-grupos-editor-botao-anexar {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		font-size: 15px !important;
		font-weight: bold;
		padding: 2px 4px !important;
		box-shadow: none;
		border: none;
	}

		.sala-grupos-editor-botao-anexar:focus {
			box-shadow: none !important;
			border: none !important;
		}

		.sala-grupos-editor-botao-anexar i {
			padding-left: 4px !important;
			font-weight: bold;
		}

		.sala-grupos-editor-botao-anexar * {
			font: normal normal bold 12px/14px "Font Awesome 5 Free", serif !important;
			font-family: "Font Awesome 5 Free" !important;
			line-height: normal !important;
			font-size: 15px !important;
		}

	.align-itens {
		text-align: end;
	}

	.btn-size-send-file {
		width: 22px;
		height: 22px;
	}

		.btn-size-send-file:hover {
			cursor: pointer;
		}
</style>
