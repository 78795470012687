<template>
    <b-dropdown 
        class="botao-dropdown"
        right
        size="sm" 
        text="Small"
        menu-class="botao-dropdown-menu"
    >
        
        <template #button-content>
            <slot>

            </slot>
        </template>

        <b-dropdown-item-button 
            button-class="botao-dropdown-item"
            v-for="(opcao, index) in opcoes"
            v-bind:key="index"
            @click="$emit(opcao.evento, opcao.texto)" 
        >
            {{ opcao.texto }}<span v-if="opcao.canal === 6">({{qntMailingPendente}})</span>
        </b-dropdown-item-button>

    </b-dropdown>
</template>

<script>
export default {
    name: 'BotaoDropdown',
    props: {
        opcoes: {
            type: Array,
            default: () => []
        },
        qntMailingPendente:0
    },
    
}
</script>

<style>
.botao-dropdown > .dropdown-toggle {
    border-radius: 0px;
    font-weight: bold;
    color: #676767 !important;
}
.botao-dropdown > .dropdown-toggle,
.botao-dropdown > .dropdown-toggle:active,
.botao-dropdown > .dropdown-toggle:focus,
.botao-dropdown > .dropdown-toggle:hover {
    background-color: #bbb !important;
    outline: 0 !important;
    border: none;
}

.botao-dropdown-menu {
    background-color: #bbb;
    margin-top: -1px;
    border-radius: 0px;
    min-width: 6rem;
    box-shadow: 1px 2px 5px #999999;
}

.botao-dropdown-item,
.botao-dropdown-item:active,
.botao-dropdown-item:focus {
    outline: 0;
    border: none;
    background: none;
}

.botao-dropdown-item {
    padding: 0.25rem 1rem;
    color: #676767;
    font-weight: bold;
    font-size: 12px;
}

</style>
