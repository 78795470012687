<template>
    <div class="sala-grupos-mensagem">
        <h6 class="sala-grupos-mensagem-cabecalho">
            <span class="sala-grupos-mensagem-nome">{{nome}}</span> [{{ dataHoraFormatada }}]
        </h6>

        <div v-if="tipoMensagem === 'text' || tipoMensagem === ''"
             class="sala-grupos-mensagem-conteudo app-ck-html-container"
             :class="ehMinhaMensagem? 'sala-grupos-minha-mensagem':'sala-grupos-mensagem-cliente'"
             v-html="mensagem">
        </div>
        <div v-else
             class="sala-grupos-mensagem-conteudo app-ck-html-container"
             :class="ehMinhaMensagem? 'sala-grupos-minha-mensagem':'sala-grupos-mensagem-cliente'">

            <MensagemArquivo :arquivo="mensagemArquivo"
                             @abrirArquivo="abrirArquivo" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import * as SDUtils from '@/assets/js/SDUtils';

    export default {
    name: 'SalaGruposMensagem',

    props: {
        tipoMensagem: {
            type: String,
            default: 'text'
        },
        nomeArquivo: {
            type: String,
            default: ''
        },
        mensagem: {
            type: String,
            default: ''
        },
        dataHora: [Date, String],
        dataHoraString: {
            type: String,
            default: ''
        },
        nome: {
            type: String,
            default: 'Cliente'
        },
        ehMinhaMensagem: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        dataHoraFormatada() {
            if(this.dataHoraString) {
                return this.dataHoraString
            }

            if(!this.dataHora) {
                return ''
            }

            if(this.dataHora && typeof this.dataHora === 'string') {
                return this.dataHora
            }

            if(this.dataHora && typeof this.dataHora === 'object') {
                return SDUtils.dataHoraFormatada(this.dataHora)
            }

            return ''
        },
        mensagemArquivo() {
            if (this.tipoMensagem !== 'text' || this.tipoMensagem !== '') {
                const descricaoArquivos = [
                    {
                        tipo: 'image',
                        descricao: 'imagem'
                    },
                    {
                        tipo: 'document',
                        descricao: 'documento'
                    },
                    {
                        tipo: 'sticker',
                        descricao: 'sticker'
                    },
                    {
                        tipo: 'audio',
                        descricao: 'audio'
                    },
                    {
                        tipo: 'ptt',
                        descricao: 'audio'
                    },
                    {
                        tipo: 'video',
                        descricao: 'video'
                    },
                ]

                return {
                    descricaoTipo: descricaoArquivos.find(x => x.tipo === this.tipoMensagem)?.descricao.toUpperCase(),
                    nomeArquivo: this.nomeArquivo,
                    mensagem: this.mensagem,
                    meuArquivo: this.ehMinhaMensagem
                }
            }
            return {};
        }
    },
    methods: {
        abrirArquivo(nomeArquivo) {
            let tamanhoLargura = window.screen.availWidth - 300;
            let tamanhoAltura = window.screen.availHeight - 150;
            let top = (screen.height - tamanhoAltura) / 4;
            let left = (screen.width - tamanhoLargura) / 2;
            window.open(`${window.location.origin}/arquivos/whatsapp/${nomeArquivo}`, '', `width=${tamanhoLargura},height=${tamanhoAltura},top=${top},left=${left}`);
        },
    }
}

</script>

<style scoped>
    .sala-grupos-mensagem {
        max-width: 100%;
    }

    .sala-grupos-mensagem-cabecalho {
        font-size: 12px;
        margin-bottom: 2px;
    }

    .sala-grupos-mensagem-nome {
        font-weight: bold;
    }

    .sala-grupos-mensagem-conteudo {
        padding: 10px 16px;
        font-size: 13px;
        word-break: break-all;
    }

    .sala-grupos-minha-mensagem {
        color: #fff;
        background-color: var(--cor-primaria-cliente);
    }

    .sala-grupos-mensagem-cliente {
        color: #0C0303;
        background-color: #D6D3D3;
    }
</style>

<style>
    .sala-grupos-mensagem-conteudo img {
        max-width: 100%;
        height: auto;
    }

    .sala-grupos-minha-mensagem a {
        color: #fff;
    }

    .read-mensagem {
        animation-name: readMsg;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes readMsg {
        0% {
            transform: rotateY(0deg);
        }

        50% {
            transform: rotateY(90deg);
        }

        100% {
            transform: rotateY(0deg);
            color: blue;
        }
    }
    
</style>