<template>
    <div class="botao-aba-sala-grupos"
         :style="loading ? '' : '; border-right-width: 6px;'"
         @click="$emit('click')"
         :class="selecionado? 'selecionado': ''">
        <div class="pulse" v-if="!selecionado" :style="controleNotificacao"></div>
        <div v-if="exibirBotaoFechar" @click="$emit('fechar-aba')" class="botao-aba-sala-grupos-fechar">
            <i class="fas fa-times-circle botao-aba-sala-grupos-icone-fechar"></i>
        </div>
       
        <slot></slot>
    </div>
</template>

<script>
   
    import buttonSfx from '@/assets/lib/audio/Pop-up-sound-effect.mp3'
    
    export default {
        name: 'BotaoAbaSalaGrupos',
        components: { },
        data: function () {
            return {
                habilitaNotificacao: true,
                colorNotificacao: 'Green',
            }
        },

        props: {
            tipoNotificacao: Number,
            selecionado: {
                type: Boolean,
                default: false
            },
            exibirBotaoFechar: {
                type: Boolean,
                default: false
            },
           
            controleNotificacao: {
                type: String,
                default: ''
            },
            loading: {
                type: Boolean,
                default: false
            },

            origem: Number,

            etiqueta: {
                type: String,
                default: '#ccc'
            }
        },
       
        created() {
            // audio 
            if (this.controleNotificacao) {
                this.play()
                
            }
        },
        methods: {
            play: function () {
                let audio = new Audio(buttonSfx);
                audio.play();

            },
        },
        computed: {
            trocarClasseNotifica: function () {
                switch (this.tipoNotificacao) { 
                    case 2:
                        this.colorNotificacao = 'blue'
                        break;
                    default:
                        this.colorNotificacao = 'green'
                }
                return this.colorNotificacao;
            }
        },
          
    }
</script>

<style scoped>

    .botao-aba-sala-grupos {
        background-color: var(--cinza-2);
        width: auto;
        font-size: 19px;
        color: var(--cinza-5);
        cursor: pointer;
        text-align: center;
        position: relative;
        margin-left:2px;
        min-width: 150px;
    }

        .botao-aba-sala-grupos:hover {
            opacity: 0.7;
            text-decoration: underline;
        }

        .botao-aba-sala-grupos.selecionado {
            background-color: var(--cinza-3);
            cursor: initial;
        }

            .botao-aba-sala-grupos.selecionado:hover {
                opacity: 1;
                text-decoration: initial;
            }

    .botao-aba-sala-grupos-fechar {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        padding: 0;
        background-color: transparent;
        font-size: 12px;
    }

        .botao-aba-sala-grupos-fechar:hover {
            background-color: #fff;
            color: black;
        }

    .botao-aba-sala-grupos-icone-fechar {
        vertical-align: top;
    }

    .pulse {
        width: 8px;
        display: table;
        /*background-color: green;*/
        height: 8px;
        border-radius: 50%;
        animation: pulse 0.7s infinite;
        animation-direction: alternate;
        -webkit-animation-name: pulse;
        animation-name: pulse;
        position:absolute
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-transform: scale(1);
            -webkit-filter: brightness(100%);
        }

        100% {
            -webkit-transform: scale(1.1);
            -webkit-filter: brightness(200%);
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            filter: brightness(100%);
        }

        100% {
            transform: scale(1.1);
            filter: brightness(200%);
        }
    }
</style>
